<template>
  <div id="CategoryDetail" class="section">
    <div class="columns is-multiline is-vcentered">
      <!-- Back Button -->
      <div class="column is-1">
        <div @click.prevent="navigateToCategoryManage">
          <b-icon class="clickable-icon" icon="chevron-left" size="is-medium" />
        </div>
      </div>
      <!-- Title -->
      <div class="column is-7-desktop is-7-tablet is-12-mobile">
        <h1 class="title">CATEGORY DETAILS</h1>
      </div>
      <!-- Create Button -->
      <div
        class="column is-2 is-hidden-mobile"
        :class="categoryObject ? '' : 'is-offset-2'"
      >
        <b-button
          expanded
          type="is-primary"
          icon-left="upload"
          :disabled="disableCreate"
          @click.prevent="uploadNewCategoryButtonPressed"
        >
          {{ categoryObject ? "Update" : "Create" }}
        </b-button>
      </div>
      <!-- Remove Button -->
      <div v-if="categoryObject" class="column is-2 is-hidden-mobile">
        <b-button
          expanded
          outlined
          type="is-danger"
          icon-left="close"
          @click.prevent="presentConfirmRemoveCategory"
        >
          Remove
        </b-button>
      </div>
    </div>

    <div class="container">
      <div style="margin-top: 1.5rem;">
        <div class="columns">
          <div class="column has-text-centered">
            <!-- Image Upload -->
            <b-field label="Banner Image" />
            <image-file-upload
              :mainImageUrl="mainImage"
              @uploaded_file="val => (mainImage = val)"
            />
            <p style="padding-top: 1rem; color: #676767;">
              Aspect Ratio 3:2 ( recommended 900x600px ) max size: 2048px
            </p>
          </div>

          <div class="column">
            <!-- Title + Subtitle + Type -->
            <b-tabs type="is-boxed">
              <!-- ENGLISH -->
              <b-tab-item label="English">
                <b-field label="Title">
                  <b-input v-model="en_title" />
                </b-field>
                <b-field label="Description">
                  <b-input v-model="en_subtitle" type="textarea" />
                </b-field>
              </b-tab-item>
              <!-- CHINESE -->
              <b-tab-item label="Chinese">
                <b-field label="Title">
                  <b-input v-model="zh_title" />
                </b-field>
                <b-field label="Description">
                  <b-input v-model="zh_subtitle" type="textarea" />
                </b-field>
              </b-tab-item>
              <!-- THAI -->
              <b-tab-item label="Thai">
                <b-field label="Title">
                  <b-input v-model="th_title" />
                </b-field>
                <b-field label="Description">
                  <b-input v-model="th_subtitle" type="textarea" />
                </b-field>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>
      <!-- Mobile : Create Button -->
      <div class="is-hidden-tablet" style="margin-top: 1.5rem;">
        <b-button
          expanded
          type="is-primary"
          @click.prevent="uploadNewCategoryButtonPressed"
        >
          Create
        </b-button>
      </div>
      <!-- Mobile: Remove Button -->
      <div
        v-if="categoryObject"
        class="is-hidden-tablet"
        style="margin-top: 1rem;"
      >
        <b-button
          expanded
          outlined
          type="is-danger"
          icon-left="close"
          @click.prevent="presentConfirmRemoveCategory"
        >
          Remove
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryApi from "@/apis/CategoryApi";
import AlertManager from "@/utils/AlertManager";
import ImageFileUpload from "@/components/forms/ImageFileUpload";

export default {
  name: "BannerDetail",
  components: {
    ImageFileUpload
  },
  props: ["category"],
  data() {
    return {
      mainImage: null,
      en_title: null,
      en_subtitle: null,
      zh_title: null,
      zh_subtitle: null,
      th_title: null,
      th_subtitle: null,
      typeOption: null,
      isEnableRemove: false,
      categoryObject: this.category
    };
  },
  computed: {
    disableCreate: function() {
      if (
        (this.mainImage &&
          this.en_title &&
          this.en_subtitle &&
          this.zh_title &&
          this.zh_subtitle &&
          this.th_title &&
          this.th_subtitle) ||
        this.categoryObject
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    setSelectedProduct: function(value) {
      this.selectedProduct = value;
    },
    setSelectedNews: function(value) {
      this.selectedNews = value;
    },
    uploadNewCategoryButtonPressed: async function() {
      let params = {
        main_image: this.mainImage,
        cover_image: this.mainImage,
        title: {
          en: this.en_title,
          th: this.th_title,
          zh: this.zh_title
        },
        description: {
          en: this.en_subtitle,
          th: this.th_subtitle,
          zh: this.zh_subtitle
        }
      };

      //   Conditional update if _id is exist, then will create instead
      if (this.categoryObject) {
        this.updateExistingCategory(this.categoryObject._id, params);
      } else {
        this.createNewCategory(params);
      }
    },
    createNewCategory: async function(categoryInfo) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CategoryApi.requestCreateCategory(categoryInfo);
      loadingComponent.close();
      AlertManager.showSuccessSnackbar(
        "Category " + response.title + " was scucessfully created."
      );
      this.categoryObject = response;
    },
    updateExistingCategory: async function(category_id, categoryInfo) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      await CategoryApi.requestUpdateCategory(category_id, categoryInfo);
      loadingComponent.close();
      AlertManager.showSuccessSnackbar("Category was scucessfully updated.");
    },
    presentConfirmRemoveCategory: function() {
      if (this.categoryObject._id) {
        this.$buefy.dialog.confirm({
          title: "Confirm Remove Category",
          message:
            "Are you sure you want to permanently remove <b>" +
            this.categoryObject.title.en +
            "</b>?",
          confirmText: "Confirm",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.removeCategory(this.categoryObject._id)
        });
      }
    },
    removeCategory: async function(category_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CategoryApi.removeCategoryById(category_id);
      loadingComponent.close();
      if (response) {
        this.navigateToCategoryManage();
      }
    },
    navigateToCategoryManage: function() {
      this.$router.push({ name: "category" });
    }
  },
  mounted() {
    if (this.categoryObject) {
      this.mainImage = this.categoryObject.main_image;
      this.en_title = this.categoryObject.title.en;
      this.en_subtitle = this.categoryObject.description.en;
      this.zh_title = this.categoryObject.title.zh;
      this.zh_subtitle = this.categoryObject.description.zh;
      this.th_title = this.categoryObject.title.th;
      this.th_subtitle = this.categoryObject.description.th;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#CategoryDetail {
  text-align: left;
}
.clickable-icon {
  cursor: pointer;
}
</style>
