<template>
  <div id="ImageFileUpload">
    <b-field style="text-align: center;">
      <img v-if="image_url" :src="image_url" />
      <b-upload v-else v-model="file" drag-drop accept="image/*">
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"> </b-icon>
            </p>
            <p>Drop your files here or click to upload</p>
          </div>
        </section>
      </b-upload>
    </b-field>

    <b-field v-if="image_url" class="file">
      <b-upload v-model="file">
        <a class="button">
          <b-icon icon="upload"></b-icon>
          <span>Re-Upload</span>
        </a>
      </b-upload>
      <span v-if="file" class="file-name">
        {{ file.name }}
      </span>
    </b-field>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";

export default {
  name: "ImageFileUpload",
  props: ["mainImageUrl"],
  data() {
    return {
      file: null,
      image_url: this.mainImageUrl
    };
  },
  watch: {
    mainImageUrl: function(val) {
      if (val) {
        this.image_url = val;
      }
    },
    file: function(val) {
      // 2Mbs in binary equals 2097152
      if (val && val.size <= 2097152) {
        this.uploadNewImage(val);
      } else {
        this.$buefy.snackbar.open({
          message: "Error: file size should not exceed 2Mb",
          type: "is-danger"
        });
      }
    }
  },
  methods: {
    uploadNewImage: async function(file) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const uploadResponse = await Helpers.uploadImage(file);
      loadingComponent.close();
      switch (uploadResponse.code) {
        case "0":
          if (this.image_url) {
            this.deleteExistingImage(this.image_url);
          }
          // Append new upload url
          this.image_url = uploadResponse.data.urls[0];

          // Emit event to parent component
          this.$emit("uploaded_file", this.image_url);
          break;

        default:
          this.$buefy.snackbar.open({
            message:
              uploadResponse.code + " Error: " + uploadResponse.data.message,
            type: "is-danger"
          });
          break;
      }
    },
    deleteExistingImage: async function(remove_url) {
      const remove_file_name = Helpers.convertUrlToImageName(remove_url);

      // Start requesting deleting file
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      await Helpers.removeImage(remove_file_name);
      loadingComponent.close();
    }
  }
};
</script>

<style></style>
